export var SimpleTimeout = {
  name: "simple-timeout",
  component: "MainPanel",
  layout: "main-panel large padded align-center",
  mode: "visible",
  text: "timeout.select",
  allowFlag: true,
  allowCancel: true,
  showOn: [{ state: "primary", value: "simple-timeout", operator: "is" }],
  children: [
    {
      name: "team-0-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "official-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "official",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "officials", type: "subType" },
            {
              action: "clearData",
              value: ["entityId"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
