export const Made2Button = {
  name: "made2-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "made.2pt",
  showForInsert: true,
  showOn: [{ state: "period.periodStatus", value: "inProgress", operator: "is" }],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "2pt", type: "eventType" },
        { action: "saveData", value: true, type: "success" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "shot-player", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const Made2AndOneButton = {
  name: "made2AndOne-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "made.2ptAndOne",
  showForInsert: true,
  showOn: [
    {
      state: "period.periodStatus",
      value: "inProgress",
      operator: "is",
    },
  ],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        {
          action: "saveData",
          value: [{ option: "foulOnShot", value: true }],
          type: "options",
        },
        { action: "saveData", value: "2pt", type: "eventType" },
        { action: "saveData", value: true, type: "success" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "shot-player", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const Made3Button = {
  name: "made3-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "made.3pt",
  showForInsert: true,
  showOn: [{ state: "period.periodStatus", value: "inProgress", operator: "is" }],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "3pt", type: "eventType" },
        { action: "saveData", value: true, type: "success" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "shot-player", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const Made3AndOneButton = {
  name: "made3AndOne-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "made.3ptAndOne",
  showForInsert: true,
  showOn: [
    {
      state: "period.periodStatus",
      value: "inProgress",
      operator: "is",
    },
  ],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        {
          action: "saveData",
          value: [{ option: "foulOnShot", value: true }],
          type: "options",
        },
        { action: "saveData", value: "3pt", type: "eventType" },
        { action: "saveData", value: true, type: "success" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "shot-player", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const Missed2Button = {
  name: "missed2-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "missed.2pt",
  showForInsert: true,
  showOn: [{ state: "period.periodStatus", value: "inProgress", operator: "is" }],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "2pt", type: "eventType" },
        { action: "saveData", value: false, type: "success" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "shot-player", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const Missed3Button = {
  name: "missed3-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "missed.3pt",
  showForInsert: true,
  showOn: [{ state: "period.periodStatus", value: "inProgress", operator: "is" }],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "3pt", type: "eventType" },
        { action: "saveData", value: false, type: "success" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "shot-player", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const TurnoverWhistleButton = {
  name: "turnover-whistle-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "turnover.whistle",
  showForInsert: true,
  showOn: [{ state: "period.periodStatus", value: "inProgress", operator: "is" }],
  actionGroups: [
    {
      actions: [
        { action: "setPossession", value: { currentEntity: false }, type: "main" },
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "turnover", type: "eventType" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "turnover-player-select", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const TurnoverNoWhistleButton = {
  name: "turnover-no-whistle-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "turnover.noWhistle",
  showForInsert: true,
  showOn: [{ state: "period.periodStatus", value: "inProgress", operator: "is" }],
  actionGroups: [
    {
      actions: [
        { action: "setPossession", value: { currentEntity: false }, type: "main" },
        { action: "saveData", value: "turnover", type: "eventType" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "turnover-player-select", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const FoulButton = {
  name: "foul-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "foul",
  showForInsert: true,
  showOn: [
    {
      state: "period.periodStatus",
      value: "inProgress",
      operator: "is",
    },
  ],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "foul", type: "eventType" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "foul-player-select", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const ShootingFoulButton = {
  name: "shooting-foul-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "action-button.foul.shooting",
  showForInsert: true,
  showOn: [
    {
      state: "period.periodStatus",
      value: "inProgress",
      operator: "is",
    },
  ],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "foul", type: "eventType" },
        { action: "saveData", value: "personal", type: "subType" },
        {
          action: "saveData",
          value: [
            { option: "shooting", value: true },
            { option: "freeThrows", value: 2 },
          ],
          type: "options",
        },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: "foul-player-select", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const SimpleJumpBallButton = {
  name: "simple-jumpball-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "jumpball",
  showForInsert: true,
  showOn: [{ state: "clock.displayTime", value: "00:00", operator: "not" }],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "jumpBall", type: "eventType" },
        { action: "saveData", value: "won", type: "subType" },
        { action: "saveData", value: { hasPossession: true }, type: "entityId" },
        { action: "sendEvent", value: "new", type: "main" },
      ],
      conditions: [
        {
          item: "period",
          value: "pending",
          event: "periodStatus",
          operator: "not",
        },
      ],
    },
    {
      actions: [
        {
          action: "nextState",
          value: "jumpball-start",
          type: "primary",
        },
      ],
      conditions: [
        {
          item: "period",
          value: "pending",
          event: "periodStatus",
          operator: "is",
        },
        {
          item: "period",
          value: 1,
          event: "periodId",
          operator: "is",
        },
      ],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const SimpleTimeoutButton = {
  name: "simple-timeout-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "timeout",
  showForInsert: true,
  showOn: [
    {
      state: "period.periodStatus",
      value: "inProgress",
      operator: "is",
    },
  ],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "timeOut", type: "eventType" },
        {
          action: "nextState",
          value: "simple-timeout",
          type: "primary",
        },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};
