import React, { useEffect, useState, useContext } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";

import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";

import "./NewPositionSelect.scss";

import ice3Rink from "../../../assets/ice3/rink.png";

import basketballCourt from "../../../assets/basketball/surface.png";
import basketballInsertCourt from "../../../assets/basketball/surface-insert.png";
import basketballRegions from "../../../assets/basketball/regions.png";
import HockeyField from "./hockey/Field";
import IndoorHockeyField from "./hockey/IndoorField";
import HandballCourt from "./handball/Court";
import Hockey5sField from "./hockey/Hockey5sField";
import { HoverEventContext } from "../../../Config/HoverEvent";

const regionsForSport = {
  basketball: [
    { name: "P1", value: "0000ff" },
    { name: "P1", value: "0055aa" },
    { name: "P1", value: "0053ac" },
    { name: "C1", value: "00ff00" },
    { name: "C1", value: "00a956" },
    { name: "M", value: "00ffff" },
    { name: "P2", value: "ffff00" },
    { name: "P2", value: "ffac00" },
    { name: "P2", value: "ffaa00" },
    { name: "C2", value: "ff0000" },
  ],
  handball: [
    { name: "RW1", value: "2a4cd7" },
    { name: "6MR1", value: "814a19" },
    { name: "6MC1", value: "000000" },
    { name: "6ML1", value: "29d0d0" },
    { name: "LW1", value: "ad2224" },
    { name: "9MR1", value: "9dafff" },
    { name: "9MC1", value: "81c57a" },
    { name: "9ML1", value: "8126c0" },
    { name: "RW2", value: "1d6914" },
    { name: "6MR2", value: "e9debb" },
    { name: "6MC2", value: "575757" },
    { name: "6ML2", value: "000080" },
    { name: "LW2", value: "ffcdf3" },
    { name: "9MR2", value: "ffee32" },
    { name: "9MC2", value: "ff9233" },
    { name: "9ML2", value: "a0a0a0" },
    { name: "OUT", value: "ffffff" },
  ],
};

const NewPositionSelect = (props) => {
  const { currentState, updateState, panel } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const [isInsertMode, setIsInsertMode] = useState(false);

  const [markTop, setMarkerTop] = useState(0);
  const [markLeft, setMarkerLeft] = useState(0);
  const [hoverMarkVisible, setHoverMarkVisible] = useState(false);
  const [hoverMarkTop, setHoverMarkTop] = useState(0);
  const [hoverMarkLeft, setHoverMarkLeft] = useState(0);

  const { sport, season } = useContext(FixtureDetailsContext);
  const { hoverEvent } = useContext(HoverEventContext);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone]);

  useEffect(() => {
    if (currentState.insertMode && currentState.insertMode.enabled === true) {
      setIsInsertMode(true);
    } else {
      setIsInsertMode(false);
    }
  }, [currentState.insertMode]);

  useEffect(() => {
    if (hoverEvent && hoverEvent.x && hoverEvent.y) {
      setHoverMarkLeft(hoverEvent.x - 0.5);
      setHoverMarkTop(hoverEvent.y - 1.75);
      setHoverMarkVisible(true);
    } else {
      setHoverMarkVisible(false);
      setHoverMarkLeft(0);
      setHoverMarkTop(0);
    }
  }, [hoverEvent]);

  function getPos(ev) {
    const offsetWidth = ev.target.offsetWidth ?? ev.target.parentElement.offsetWidth;
    const offsetHeight = ev.target.offsetHeight ?? ev.target.parentElement.offsetHeight;
    const markerPosX = parseFloat(((ev.nativeEvent.offsetX / offsetWidth) * 100).toFixed(2));
    const markerPosY = parseFloat(((ev.nativeEvent.offsetY / offsetHeight) * 100).toFixed(2));

    updateState("pos", { x: markerPosX, y: markerPosY });

    if (sport === "handball" || sport === "basketball") {
      updateState("region", getPoint(markerPosX, markerPosY));
    }

    setMarkerLeft(markerPosX - 0.5);
    setMarkerTop(markerPosY - 1.75);
    updateState("markerPos", { x: markerPosX, y: markerPosY });

    setDoAction(true);
    setIsOpen(true);
  }

  function getPoint(posX, posY) {
    const regions = regionsForSport[sport] ?? [];
    const img = document.getElementById("base-play-area-regions");
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const pointX = (canvas.width / 100) * posX;
    const pointY = (canvas.height / 100) * posY;

    canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
    const pixelData = canvas.getContext("2d").getImageData(pointX, pointY, 1, 1).data;
    canvas.remove();
    const region = regions.find((r) => r.value === rgbToHex(pixelData[0], pixelData[1], pixelData[2]));

    // Regions SVG debug

    // let row = [];
    // let rows = [];
    // for (let vert = 0; vert < canvas.height; vert++) {
    //   row = [];
    //   for (let horiz = 0; horiz < canvas.width; horiz++) {
    //     var color = canvas.getContext("2d").getImageData(horiz, vert, 1, 1)
    //       .data;
    //     row.push(rgbToHex(color[0], color[1], color[2]) + " ");
    //   }
    //   rows.push(row);
    // }

    if (region) {
      return region.name;
    } else {
      return "M";
    }
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  return isVisible ? (
    <div className={panel.layout + " enabled-" + isEnabled + " insertMode-" + isInsertMode}>
      <div className="base-play-area-container">
        {sport === "ice3" && (
          <img id="base-play-area" alt="base-play-area" src={ice3Rink} onClick={getPos} draggable={false} />
        )}
        {sport === "basketball" && (
          <>
            <img
              id="base-play-area"
              alt="base-play-area"
              src={isInsertMode ? basketballInsertCourt : basketballCourt}
              onClick={getPos}
              draggable={false}
            />
            <img
              id="base-play-area-regions"
              alt="base-play-area-regions"
              style={{ display: "none" }}
              src={basketballRegions}
            />
          </>
        )}
        {sport === "hockey" && season.discipline === "HOCKEY5S" && <Hockey5sField {...props} />}
        {sport === "hockey" && season.discipline === "OUTDOOR" && <HockeyField {...props} />}
        {sport === "hockey" && season.discipline === "INDOOR" && <IndoorHockeyField {...props} />}
        {sport === "handball" && <HandballCourt onClick={getPos} props={props} />}
        {isOpen && !hoverMarkVisible && (
          <div
            className="court-mark"
            style={{ top: markTop + "%", left: markLeft + "%" }}
            onClick={(e) => e.stopPropagation()}
          ></div>
        )}
        {hoverMarkVisible && (
          <div
            className="court-mark"
            style={{ top: hoverMarkTop + "%", left: hoverMarkLeft + "%" }}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        <div id="FloatingPanelContainer" />
      </div>
    </div>
  ) : null;
};

export default NewPositionSelect;
