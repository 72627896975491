const commonAssistActions = [
  { action: "saveData", value: "assist", type: "eventType" },
  { action: "clearData", value: ["options", "subType", "scores", "success"], type: "main" },
  { action: "sendEvent", value: "new", type: "main" },
];

const commonNextStepActionGroup = [
  {
    actions: [{ action: "nextState", value: null, type: "primary" }],
    conditions: [{ item: "options", value: "foulOnShot", event: "shot", operator: "not" }],
  },
  {
    actions: [{ action: "nextState", value: "and1-foul-player-select", type: "primary" }],
    conditions: [{ item: "options", value: "foulOnShot", event: "shot", operator: "is" }],
  },
];

export const AssistPlayerSelect = {
  name: "assist-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "assist.select-player",
  showOn: [{ state: "primary", value: "assist-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "assist-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },
      actionGroups: [
        {
          actions: [...commonAssistActions],
          conditions: [],
        },
        ...commonNextStepActionGroup,
      ],
    },
    {
      name: "no-assist",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "assist.none",
      showOn: [true],
      actionGroups: [...commonNextStepActionGroup],
    },
    {
      name: "confirm-later",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      startEvent: true,
      startPlay: false,
      text: "confirmLater",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "saveData", type: "flagged", value: true },
            { action: "clearData", value: ["personId"], type: "main" },
            ...commonAssistActions,
          ],
          conditions: [],
        },
        ...commonNextStepActionGroup,
      ],
    },
  ],
};
