const shotPlayerSelectActions = [
  {
    actions: [
      { action: "sendEvent", value: "edit", type: "main" },
      { action: "copyEvent", value: "shot", type: "to" },
    ],
    conditions: [],
  },
  {
    actions: [
      { action: "copyEvent", value: "shot", type: "from" },
      { action: "nextState", value: "defence-goalkeeper", type: "primary" },
    ],
    conditions: [
      { item: "success", value: true, event: "main", operator: "not" },
      {
        item: "optionValue",
        event: "main",
        operator: "not",
        name: "failureReason",
        value: "SHOT_BLOCKED",
      },
      { item: "isInsertMode" },
    ],
  },
  {
    actions: [{ action: "nextState", value: "goal-assist", type: "primary" }],
    conditions: [
      { item: "success", value: true, event: "main", operator: "is" },
    ],
  },
  {
    actions: [
      {
        action: "nextState",
        value: "shot-block-player",
        type: "primary",
      },
    ],
    conditions: [
      { item: "success", value: true, event: "main", operator: "not" },
      {
        item: "optionValue",
        event: "main",
        operator: "is",
        name: "failureReason",
        value: "SHOT_BLOCKED",
      },
    ],
  },
  {
    actions: [
      { action: "nextState", value: null, type: "primary" },
    ],
    conditions: [
      { item: "success", value: true, event: "main", operator: "not" },
      {
        item: "optionValue",
        event: "main",
        operator: "not",
        name: "failureReason",
        value: "SHOT_BLOCKED",
      },
    ],
  },
];

export const ShotPlayerSelect = {
  name: "shot-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "shot.select-player",
  showOn: [{ state: "primary", value: "shot-player", operator: "is" }],
  allowBack: { state: "primary", value: "shot-attack-type", event: "shot" },
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "shot-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },
      actionGroups: shotPlayerSelectActions,
    },
    {
      name: "confirm-later-shot-player",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "confirmLater",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [
        {
          actions: [
            { action: "saveData", type: "flagged", value: true },
          ],
          conditions: [],
        },
        ...shotPlayerSelectActions,
      ],
    },
  ],
};