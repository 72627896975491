import { CoachFoulTypes, BenchFoulTypes, PlayerFoulTypes } from "../../subTypes/EliteFoulTypes";

import { TechnicalFoulOptions } from "../../Options/FoulOptions";

const commonFoulActions = [
  {
    actions: [
      { action: "saveData", value: "personal", type: "subType" },
      {
        action: "saveData",
        value: [{ option: "shooting", value: true }],
        type: "options",
      },
    ],
    conditions: [
      {
        item: "subType",
        value: ["shooting"],
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      {
        action: "adjustTeamValue",
        value: 1,
        options: { currentEntity: true, item: "fouls" },
      },
    ],
    conditions: [
      {
        item: "subType",
        value: ["offensive"],
        event: "main",
        operator: "not",
      },
    ],
  },
  {
    actions: [
      {
        action: "saveData",
        value: [{ option: "wasTechnical", value: false }],
        type: "options",
      },
    ],
    conditions: [
      {
        item: "subType",
        value: ["technical", "benchTechnical", "coachTechnical", "offensive"],
        event: "main",
        operator: "not",
      },
    ],
  },
  {
    actions: [
      {
        action: "nextState",
        value: "double-foul-type",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "subType",
        value: "double",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      { action: "saveData", value: "personal", type: "subType" },
      { action: "sendEvent", value: "edit", type: "main" },
      { action: "saveData", value: "throwIn", type: "subType" },
      { action: "copyEvent", value: "foul", type: "to" },
      {
        action: "nextState",
        value: "foul-drawn-player",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "subType",
        value: "throwIn",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      {
        action: "nextState",
        value: "cancellation-foul-type",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "subType",
        value: "cancellation",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      // save event data for later use (copyData)
      { action: "copyEvent", value: "foul", type: "to" },

      // send technicalFoul event
      { action: "sendEvent", value: "edit", type: "main" },

      // prevent duplicated event recordings in case of double foul
      { action: "newEventId", value: null, type: "main" },
      { action: "copyData", value: "entityId", type: "foul" },
      { action: "modifyEvent", value: "swapEntities", type: "main" },
      {
        action: "saveData",
        value: [{ option: "wasTechnical", value: true }],
        type: "options",
      },
      {
        action: "nextState",
        value: "free-throw-buttons",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "subType",
        value: ["cancellation", "double", "throwIn"],
        event: "main",
        operator: "not",
      },
      {
        item: "subType",
        value: ["technical", "coachTechnical", "benchTechnical"],
        event: "main",
        operator: "is",
      },
    ],
    operator: "all",
  },
  {
    actions: [
      { action: "copyEvent", value: "foul", type: "to" },
      { action: "sendEvent", value: "edit", type: "main" },
      { action: "newEventId", value: null, type: "main" },
      {
        action: "saveData",
        value: "turnover",
        type: "eventType",
      },
      {
        action: "saveData",
        value: "offensive",
        type: "subType",
      },
      {
        action: "clearData",
        value: ["x", "y", "options"],
        type: "main",
      },
      { action: "sendEvent", value: "new", type: "main" },
      {
        action: "setPossession",
        value: { currentEntity: false },
        type: "main",
      },
      { action: "copyEvent", value: "foul", type: "from" },
    ],
    conditions: [
      {
        item: "hasPossession",
        value: true,
        event: "main",
        operator: "is",
      },
      {
        item: "subType",
        value: [
          "double",
          "technical",
          "coachTechnical",
          "benchTechnical",
          "personal",
          "throwIn",
          "cancellation",
          "offensive",
        ],
        event: "main",
        operator: "not",
      },
      {
        item: "options",
        value: "wasTechnical",
        event: "main",
        operator: "not",
      },
    ],
    operator: "all",
  },
  {
    actions: [
      {
        action: "saveData",
        value: [{ option: "team", value: true }],
        type: "options",
      },
    ],
    conditions: [
      {
        item: "hasPossession",
        value: true,
        event: "main",
        operator: "is",
      },
      {
        item: "subType",
        value: "coachDisqualifying",
        event: "foul",
        operator: "is",
      },
    ],
    operator: "all",
  },
  {
    actions: [
      { action: "copyEvent", value: "foul", type: "to" },
      { action: "sendEvent", value: "edit", type: "main" },
      {
        action: "nextState",
        value: "foul-drawn-player",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "subType",
        value: ["cancellation", "double", "throwIn", "technical", "coachTechnical", "benchTechnical", "offensive"],
        event: "main",
        operator: "not",
      },
    ],
  },
];

// Foul Type Panel
export var FoulType = {
  name: "foul-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.type",
  showOn: [{ state: "primary", value: "foul-type", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  allowBack: { state: "primary", value: "foul-player", event: "main" },
  children: [
    // player on court foul types
    {
      name: "player-foul-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.bench",
          value: false,
          operator: "is",
          event: "main",
        },
        {
          state: "event.coach",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: PlayerFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: commonFoulActions,
    },
    // Bench Types
    {
      name: "bench-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.bench",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: BenchFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
          ],
          conditions: [],
        },
        ...commonFoulActions,
      ],
    },
    // Coach Types
    {
      name: "coach-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.coach",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: CoachFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
          ],
          conditions: [],
        },
        ...commonFoulActions,
      ],
    },
    // Technical Foul Options
    {
      name: "technical-foul-options",
      component: "OptionGroup",
      layout: "option-group",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: "offensive",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "options",
        children: TechnicalFoulOptions,
        layout: "option-button",
        prefix: "foul",
      },
    },
    {
      name: "confirm-panel",
      component: "SubPanel",
      layout: "sub-panel",
      mode: "visible",
      text: null,
      showOn: [
        {
          state: "event.subType",
          value: "offensive",
          operator: "is",
          event: "main",
        },
      ],
      actions: [],
      children: [
        {
          name: "ok-button",
          component: "TypeButton",
          layout: "match-button center action margin-top",
          mode: "enable",
          text: "ok",
          showOn: [],
          actionGroups: [
            {
              actions: [
                {
                  action: "adjustTeamValue",
                  value: 1,
                  options: { currentEntity: true, item: "fouls" },
                },
              ],
              conditions: [],
            },
            {
              actions: [
                { action: "copyEvent", value: "foul", type: "to" },
                { action: "sendEvent", value: "edit", type: "main" },
                { action: "newEventId", value: null, type: "main" },
                {
                  action: "saveData",
                  value: "turnover",
                  type: "eventType",
                },
                {
                  action: "saveData",
                  value: "offensive",
                  type: "subType",
                },
                {
                  action: "clearData",
                  value: ["x", "y", "options"],
                  type: "main",
                },
                { action: "sendEvent", value: "new", type: "main" },
                {
                  action: "setPossession",
                  value: { currentEntity: false },
                  type: "main",
                },
                { action: "copyEvent", value: "foul", type: "from" },
              ],
              conditions: [
                {
                  item: "hasPossession",
                  value: true,
                  event: "main",
                  operator: "is",
                },
              ],
              operator: "all",
            },
            {
              actions: [
                { action: "copyEvent", value: "foul", type: "to" },
                { action: "sendEvent", value: "edit", type: "main" },
                {
                  action: "nextState",
                  value: "foul-drawn-player",
                  type: "primary",
                },
              ],
              conditions: [],
            },
          ],
        },
      ],
    },
  ],
};
