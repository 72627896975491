export const FreeThrowsAwarded = {
  name: "free-throw-buttons",
  component: "MainPanel",
  layout: "main-panel large center no-group",
  mode: "visible",
  text: "freethrows.wereAnyAwarded",
  showOn: [{ state: "primary", value: "free-throw-awarded", operator: "is" }],
  children: [
    {
      name: "answer-yes",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      startEvent: true,
      startPlay: false,
      text: "yes",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 1 }],
              type: "options",
            },
            { action: "nextState", value: "free-throws-select", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "answer-no",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      startEvent: true,
      startPlay: false,
      text: "no",
      showOn: [true],
      actionGroups: [
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [],
        },
      ],
    },
  ],
};
