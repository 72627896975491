export const FreeThrowsSelect = {
  name: "free-throws-select",
  component: "MainPanel",
  layout: "main-panel full align-center",
  mode: "visible",
  text: "freethrows",
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showOn: [{ state: "primary", value: "free-throws-select", operator: "is" }],
  children: [
    {
      name: "free-throw-select-panel",
      component: "SimpleFreeThrows",
      layout: "free-throws full",
      mode: "enable",
      text: null,
      showOn: [true],
      options: {
        allowAddingNew: true,
        currentEntity: false,
        currentEntityEvent: "foul",
      },
      actionGroups: [
        {
          actions: [{ action: "clearEvent", value: "freethrow", type: "main" }],
          conditions: [],
        },
        {
          // switch possession if the last free throw was successful
          actions: [
            { action: "setPossession", value: { currentEntity: false }, type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [{ item: "success", value: true, event: "main", operator: "is" }],
        },
        {
          actions: [{ action: "nextState", value: "shot-rebound", type: "primary" }],
          conditions: [{ item: "success", value: false, event: "main", operator: "is" }],
        },
      ],
    },
  ],
};
