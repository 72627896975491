import { Clock, SwitchTeamsButton } from "./includes/Base";
import { BlockPlayer, JumpballStartGame, ReboundFollowOn, ReboundPlayer } from "./includes/Panels";
import {
  FoulButton,
  Made2AndOneButton,
  Made2Button,
  Made3AndOneButton,
  Made3Button,
  Missed2Button,
  Missed3Button,
  ShootingFoulButton,
  TurnoverNoWhistleButton,
  TurnoverWhistleButton,
  SimpleJumpBallButton,
  SimpleTimeoutButton,
} from "./includes/simpleBasketball/ActionButtons";
import { And1FoulPlayerSelect } from "./includes/simpleBasketball/And1FoulPlayerSelect";
import { AssistPlayerSelect } from "./includes/simpleBasketball/AssistPlayerSelect";
import { FoulDrawnPlayerSelect } from "./includes/simpleBasketball/FoulDrawnPlayerSelect";
import { FoulPlayerSelect } from "./includes/simpleBasketball/FoulPlayerSelect";
import { FoulTypeSelect } from "./includes/simpleBasketball/FoulTypeSelect";
import { FreeThrowsAwarded } from "./includes/simpleBasketball/FreeThrowsAwarded";
import { FreeThrowsSelect } from "./includes/simpleBasketball/FreeThrowsSelect";
import { FreeThrowsSelectLocked } from "./includes/simpleBasketball/FreeThrowsSelectLocked";
import { ShotPlayerSelect } from "./includes/simpleBasketball/ShotPlayerSelect";
import { ShotTypeSelect } from "./includes/simpleBasketball/ShotTypeSelect";
import { SimpleTimeout } from "./includes/simpleBasketball/SimpleTimeout";
import { StealPlayerSelect } from "./includes/simpleBasketball/StealPlayerSelect";
import { TurnoverPlayerSelect } from "./includes/simpleBasketball/TurnoverPlayerSelect";

const SimpleBasketballWorkflow = {
  name: "simple",
  sport: "basketball",
  managePossession: true,
  manageAlternatingArrow: true,
  displayEventLog: true,
  defaultLocations: [
    { x: 5, y: 50 },
    { x: 95, y: 50 },
  ],
  panels: [
    {
      name: "main-area",
      component: "StackingPanel",
      showOn: [true],
      layout: "column",
      children: [
        {
          name: "top-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "team-0-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              possessionIcon: "basketball-ball",
              showPossessionIcon: true,
              showArrow: true,
              showStats: true,
            },
            {
              name: "clock-and-buttons",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-parent clock-and-buttons",
              children: [
                Clock,
                {
                  name: "match-buttons",
                  component: "ButtonPanel",
                  layout: "match-buttons-stackable",
                  mode: "visible",
                  showOn: [true],
                  children: [SimpleTimeoutButton, SwitchTeamsButton, SimpleJumpBallButton],
                },
              ],
            },
            {
              name: "team-1-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              possessionIcon: "basketball-ball",
              showPossessionIcon: true,
              showArrow: true,
              showStats: true,
            },
          ],
        },
        {
          name: "play-area",
          component: "StackingPanel",
          showOn: [true],
          layout: "fill-parent",
          children: [
            {
              name: "players-0",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fit-content court-margin",
              children: [
                {
                  name: "team-0-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
              ],
            },
            {
              name: "middle-play-area",
              component: "StackingPanel",
              showOn: [true],
              layout: "row fill-content gapped",
              children: [
                {
                  name: "middle-play-area",
                  component: "StackingPanel",
                  showOn: [true],
                  layout: "column half-fill",
                  children: [
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.scoring",
                      options: { team: 0 },
                      layout: "row full twoInRow",
                      children: [Made2Button, Made3Button, Made2AndOneButton, Made3AndOneButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.missed",
                      options: { team: 0 },
                      layout: "row full twoInRow",
                      children: [Missed2Button, Missed3Button],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.foul",
                      options: { team: 0 },
                      layout: "row full twoInRow",
                      children: [FoulButton, ShootingFoulButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.other",
                      options: { team: 0 },
                      layout: "row full twoInRow freeHeightButtons",
                      children: [TurnoverWhistleButton, TurnoverNoWhistleButton],
                    },
                  ],
                },
                {
                  name: "middle-play-area",
                  component: "StackingPanel",
                  showOn: [true],
                  layout: "column half-fill",
                  children: [
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.scoring",
                      options: { team: 1 },
                      layout: "row full twoInRow",
                      children: [Made2Button, Made3Button, Made2AndOneButton, Made3AndOneButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.missed",
                      options: { team: 1 },
                      layout: "row full twoInRow",
                      children: [Missed2Button, Missed3Button],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.foul",
                      options: { team: 1 },
                      layout: "row full twoInRow",
                      children: [FoulButton, ShootingFoulButton],
                    },
                    {
                      name: "team-0-action-buttons",
                      component: "TeamButtonGroup",
                      mode: "visible",
                      showOn: [true],
                      text: "events.other",
                      options: { team: 1 },
                      layout: "row full twoInRow freeHeightButtons",
                      children: [TurnoverWhistleButton, TurnoverNoWhistleButton],
                    },
                  ],
                },
              ],
            },
            {
              name: "players-1",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fit-content court-margin",
              children: [
                {
                  name: "team-1-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
              ],
            },
          ],
        },
      ],
    },
    ShotPlayerSelect,
    ShotTypeSelect,
    AssistPlayerSelect,
    ReboundPlayer,
    ReboundFollowOn,
    BlockPlayer,
    FoulPlayerSelect,
    FoulDrawnPlayerSelect,
    FoulTypeSelect,
    FreeThrowsAwarded,
    FreeThrowsSelect,
    FreeThrowsSelectLocked,
    And1FoulPlayerSelect,
    SimpleTimeout,
    JumpballStartGame,
    StealPlayerSelect,
    TurnoverPlayerSelect,
  ],
};

export default SimpleBasketballWorkflow;
