import { useState, useEffect, useContext } from "react";
import { v1 as uuidv1 } from "uuid";

import {
  sendEvent,
  handleLinkedEvent,
  getEventTime,
  getEventTimeForUpdatedDeletedEvents,
} from "../Utilities/SendEvent";
import { calculateOnCourt } from "../Utilities/CalculateOnCourt";
import { sendPossessionEvent } from "../Utilities/PossessionEvents";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { InactivityTrackerContext } from "../../../Config/InactivityTracker";
import Logger from "../Utilities/Logger";
import { sendClockEvent } from "../Utilities/ClockEvents";
import { isPowerPlayActive, isPowerPlayEnabled } from "../Utilities/MatchProfile";

const logger = Logger.getInstance();

export const useDoActions = (panel, props, doAction, customActionGroups = null) => {
  const { currentState, updateState } = props;
  const [actionsDone, setActionsDone] = useState(false);
  const { fixtureProfile } = useContext(FixtureDetailsContext);
  const { setLastActionTime } = useContext(InactivityTrackerContext);

  useEffect(() => {
    let nextState = {};
    if (doAction === true) {
      logger.log("do actions", panel);
      setLastActionTime(Date.now());
      let currentEvents = props.currentState.events;
      if (panel.startPlay) {
        let uuid = uuidv1();
        updateState("playId", uuid);
        currentEvents.main["eventTime"] = getEventTime();
        currentEvents.main["clock"] = _formatClock(props.currentState.clock.displayTime);
        currentEvents.main["periodId"] = props.currentState.period.periodId;
        currentEvents.main["playId"] = uuid;
        delete currentEvents.main.flagged;
        delete currentEvents.main.personId;
        updateState("events", currentEvents);
      }
      if (panel.startEvent) {
        if (currentEvents.main.back) {
          delete currentEvents.main.back;
        } else {
          _newEvent();
        }
      }

      if (panel.softStartEvent) {
        _softNewEvent();
      }

      if (panel.inheritPlay && props.currentState.events[panel.inheritPlay]) {
        props.currentState.events.main.playId = props.currentState.events[panel.inheritPlay].playId;
        updateState("playId", props.currentState.events[panel.inheritPlay].playId);
      }

      const actionGroups = customActionGroups ? customActionGroups : panel.actionGroups;

      if (actionGroups) {
        actionGroups.map((actionGroup) => {
          if (_checkConditions(actionGroup)) {
            actionGroup.actions.map((action) => {
              switch (action.action) {
                case "saveData":
                  _saveData(action);
                  break;
                case "clearData":
                  _clearData(action);
                  break;
                case "copyData":
                  _copyData(action);
                  break;
                case "copyEvent":
                  _copyEvent(action);
                  break;
                case "clearEvent":
                  _clearEvent(action);
                  break;
                case "newEvent":
                  _newEvent();
                  break;
                case "newEventId":
                  _newEvent(false);
                  break;
                case "newPlayId":
                  _newPlayId();
                  break;
                case "adjustTeamValue":
                  _adjustTeamValue(action);
                  break;
                case "setState":
                  updateState(action.type, action.value);
                  break;
                case "setPossession":
                  _setValue(action, "hasPossession");
                  break;
                case "setArrow":
                  _setValue(action, "hasArrow");
                  break;
                case "calculateOnCourt":
                  _calculateOnCourt();
                  break;
                case "checkResets":
                  _checkResets();
                  break;
                case "startClock":
                  _changeClock(true);
                  break;
                case "stopClock":
                  _changeClock(false);
                  break;
                case "deleteFromPlay":
                  _deleteFromPlay(action);
                  break;
                case "switchValues":
                  _switchValues(action);
                  break;
                case "switchValuesPlay":
                  _switchValuesPlay(action);
                  break;
                case "processInserts":
                  if (props.currentState.insertStore && props.currentState.insertStore.length > 0) {
                    updateState("processInserts", true);
                  }
                  break;
                case "setPosTracking":
                  _setPosTracking(action.value);
                  break;
                case "debug":
                  logger.log("DEBUG: ", JSON.parse(JSON.stringify(props.currentState.events)));
                  break;
                case "alert":
                  alert(action.value);
                  break;
                case "copyFromPlay":
                  _copyFromPlay(action);
                  break;
                case "nextState":
                  if (!nextState[action.type]) {
                    updateState(action.type, action.value);
                    nextState[action.type] = true;
                  }
                  break;
                // this was introduced to support the penaltyCorner workflow
                // penaltyCorner workflow could be an infinite workflow with the same component
                // (penaltyCorner -> penaltyCorner -> penaltyCorner -> ...)
                // We need a proper cleanup between the steps though, and the best way to
                // achieve this is to remount the component
                case "forceNextState":
                  updateState(action.type, null);

                  window.setTimeout(() => updateState(action.type, action.value), action.delayInMiliseconds ?? 0);
                  break;
                case "sendEvent":
                  if (props.currentState.linkedEventType) {
                    handleLinkedEvent(action, props);
                  }

                  _handlePPEvent(action, props);

                  sendEvent(action, props);
                  break;
                case "startGame":
                  _startGame(action, props);
                  break;
                case "popStash":
                  const currentStash = props.currentState.stash || [];
                  if (currentStash.length > 0) {
                    const stashElem = currentStash.pop();
                    const newCurrentEvents = stashElem.events;
                    updateState("events", newCurrentEvents);
                    updateState("stash", currentStash);
                    if (stashElem.state.primary) {
                      updateState("primary", null);
                      setTimeout(() => {
                        updateState("primary", stashElem.state.primary);
                      }, 1);
                    }
                    if (stashElem.state.secondary) {
                      updateState("secondary", null);
                      setTimeout(() => {
                        updateState("secondary", stashElem.state.secondary);
                      }, 1);
                    }
                  }
                  break;
                case "modifyEvent":
                  _modifyEvent(action);
                  break;
                case "endPowerPlay":
                  _endPowerPlay();
                  break;
                case "deleteFromInsertStore":
                  if (props.currentState.insertStore && props.currentState.insertStore.length > 0) {
                    props.currentState.insertStore = props.currentState.insertStore.filter(
                      (obj) => obj[action.type] !== action.value,
                    );
                  }
                  break;
                default:
                  break;
              }
              return false;
            });
          }
          return false;
        });
      }
      setActionsDone(true);
    }
    // eslint-disable-next-line
  }, [doAction]);

  useEffect(() => {
    if (actionsDone === true) {
      setActionsDone(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  function _checkConditions(actionGroup) {
    let returnValue = [];
    if (actionGroup.conditions) {
      actionGroup.conditions.map((condition) => {
        // Set which event we are looking at (main is the default)
        let event =
          condition &&
          condition.event !== "main" &&
          typeof props.currentState.events !== "undefined" &&
          typeof props.currentState.events[condition.event] !== "undefined"
            ? props.currentState.events[condition.event]
            : props.currentState.events["main"];
        if (typeof condition !== "undefined") {
          let value =
            typeof condition.value !== "object"
              ? condition.value
              : !Array.isArray(condition.value)
              ? props.currentState.events[condition.value.type][condition.item]
              : condition.value;
          if (
            typeof event[condition.item] === "undefined" ||
            condition.item === "options" ||
            condition.item.split(".")[0] === "clock"
          ) {
            let entity = null;
            switch (condition.item.split(".")[0]) {
              case "clock":
                if (
                  (condition.operator === "is" &&
                    props.currentState.clock[condition.item.split(".")[1]] === condition.value) ||
                  (condition.operator === "not" &&
                    props.currentState.period[condition.item.split(".")[1]] !== condition.value)
                ) {
                  returnValue.push(true);
                } else {
                  returnValue.push(false);
                }

                break;
              case "period":
                if (
                  (condition.operator === "is" && props.currentState.period[condition.event] === condition.value) ||
                  (condition.operator === "not" && props.currentState.period[condition.event] !== condition.value)
                ) {
                  returnValue.push(true);
                } else {
                  returnValue.push(false);
                }
                break;
              case "hasPossession":
                let entityIndex = props.currentState.entities.findIndex((el) => el.hasPossession === true);
                if (entityIndex > -1) {
                  entity =
                    props.currentState.entities[
                      props.currentState.entities.findIndex((el) => el.hasPossession === true)
                    ].entityId;
                } else {
                  entity = props.currentState.entities[0].entityId;
                }
                if (condition.value === true && props.currentState.events[condition.event].entityId === entity) {
                  returnValue.push(true);
                } else {
                  returnValue.push(false);
                }
                break;
              case "hasArrow":
                let newEntityIndex = props.currentState.entities.findIndex((el) => el.hasArrow === true);
                if (newEntityIndex > -1) {
                  entity =
                    props.currentState.entities[props.currentState.entities.findIndex((el) => el.hasArrow === true)]
                      .entityId;
                } else {
                  entity = props.currentState.entities[0].entityId;
                }
                if (
                  (condition.value === true && props.currentState.events[condition.event].entityId === entity) ||
                  (condition.value === false && props.currentState.events[condition.event].entityId !== entity)
                ) {
                  returnValue.push(true);
                } else {
                  returnValue.push(false);
                }
                break;
              case "options":
                if (
                  (condition.operator === "is" &&
                    props.currentState.events[condition.event]?.options &&
                    props.currentState.events[condition.event]?.options[condition.value] === true) ||
                  (condition.operator === "not" &&
                    (!props.currentState.events[condition.event]?.options ||
                      (props.currentState.events[condition.event]?.options &&
                        props.currentState.events[condition.event]?.options[condition.value] !== true)))
                ) {
                  returnValue.push(true);
                } else {
                  returnValue.push(false);
                }
                break;
              case "optionValue":
                const optionValue =
                  props.currentState.events[condition.event].options &&
                  props.currentState.events[condition.event].options[condition.name];
                const resultValue =
                  condition.operator === "is" ? optionValue === condition.value : optionValue !== condition.value;
                returnValue.push(resultValue);
                break;
              case "anySuccess":
                let playEvents = props.eventStore.filter(
                  (ev) => ev.playId === props.currentState.events[condition.event].playId && ev.success === true,
                );
                if (playEvents.length > 0) {
                  returnValue.push(condition.operator === "is" ? true : false);
                } else {
                  returnValue.push(condition.operator === "not" ? true : false);
                }
                break;
              case "fixtureProfile":
                if (condition.operator === "is") {
                  returnValue.push(fixtureProfile[condition.event] === condition.value);
                } else {
                  returnValue.push(fixtureProfile[condition.event] !== condition.value);
                }
                break;
              case "fixture":
                if (condition.item.split(".")[1] === "isPowerPlayEnabled") {
                  const isPPEnabled = isPowerPlayEnabled(currentState, fixtureProfile);
                  returnValue.push(condition.operator === "is" ? isPPEnabled : !isPPEnabled);
                } else if (condition.item.split(".")[1] === "isPowerPlayActive") {
                  const isPPActive = isPowerPlayActive(currentState, fixtureProfile, props.eventStore);
                  returnValue.push(condition.operator === "is" ? isPPActive : !isPPActive);
                } else {
                  console.warn("Unknown fixture profile condition check.");
                }
                break;
              case "region":
                const entityId = props.currentState.events[condition.event].entityId;
                const teamNo = currentState.entities.findIndex((ent) => ent.entityId === entityId);
                const eventRegion =
                  teamNo === 0
                    ? currentState.region === "P1" || currentState.region === "C1"
                      ? "2pt"
                      : "3pt"
                    : currentState.region === "P2" || currentState.region === "C2"
                    ? "2pt"
                    : "3pt";
                returnValue.push(
                  condition.operator === "is" ? eventRegion === condition.value : eventRegion !== condition.value,
                );
                break;
              case "emptyProperty":
                const isPropertyEmpty = typeof event[condition.value] === "undefined";
                returnValue.push(isPropertyEmpty);
                break;
              case "eventCountForPerson":
                const { eventStore } = props;

                const filteredEvents = eventStore
                  .filter((ev) => ev.status !== "deleted")
                  .filter((oneEvent) => oneEvent.personId === event.personId)
                  .filter((oneEvent) => condition.eventType === oneEvent.eventType && oneEvent.class === "sport");

                returnValue.push(filteredEvents.length === condition.count);
                break;
              case "findInStore":
                const found = props.eventStore.find((e) => e[condition.field] === condition.value);
                returnValue.push(found !== undefined);
                break;
              case "isInsertMode":
                returnValue.push(currentState?.insertMode?.enabled === true);
                break;
              case "throwOffTeam":
                const throwOffEvent = props.eventStore.find((event) => event.eventType === "throwOff");
                const currentEntity = currentState.entities.find((entity) => entity.hasPossession);
                returnValue.push(
                  throwOffEvent.entityId === currentEntity.entityId && condition.operator === "is"
                    ? condition.value === true
                    : false,
                );
                break;
              case "goalkeeper":
                const playerEntity = currentState.entities.find((entity) => entity.entityId === event.entityId);
                const player = playerEntity.persons.find((person) => person.personId === event.personId);
                returnValue.push(condition.operator === "is" && condition.value === true && player?.position === "G");
                break;
              default:
                returnValue.push(false);
                break;
            }
          } else {
            switch (condition.operator) {
              case "is":
                if (Array.isArray(value)) {
                  if (value.includes(event[condition.item])) {
                    returnValue.push(true);
                  } else {
                    returnValue.push(false);
                  }
                } else {
                  if (event[condition.item] !== value) {
                    returnValue.push(false);
                  } else {
                    returnValue.push(true);
                  }
                }

                break;
              case "not":
                if (Array.isArray(value)) {
                  if (value.includes(event[condition.item])) {
                    returnValue.push(false);
                  } else {
                    returnValue.push(true);
                  }
                } else {
                  if (event[condition.item] === value) {
                    returnValue.push(false);
                  } else {
                    returnValue.push(true);
                  }
                }
                break;
              default:
                break;
            }
          }
        }
        return false;
      });
    }
    if (actionGroup.operator) {
      if (actionGroup.operator === "all") {
        return returnValue.includes(false) ? false : true;
      }
      if (actionGroup.operator === "any") {
        return returnValue.includes(true) ? true : false;
      }
    } else {
      return returnValue.includes(false) ? false : true;
    }
  }

  function _saveData(action) {
    let currentEvents = props.currentState.events;
    if (action.type === "variables") {
      action.value.map((variable) => {
        switch (variable) {
          case "clock":
            currentEvents["main"]["clock"] = _formatClock(props.currentState.clock.displayTime);
            break;
          case "periodId":
            currentEvents["main"]["periodId"] = props.currentState.period.periodId;
            break;
          case "x":
            currentEvents["main"]["x"] = props.currentState.pos.x;
            break;
          case "y":
            currentEvents["main"]["y"] = props.currentState.pos.y;
            break;
          case "eventTime":
            currentEvents["main"]["eventTime"] = getEventTime();
            break;
          case "scores":
            // Removed
            break;
          case "hasPossession":
            let entity =
              props.currentState.entities[props.currentState.entities.findIndex((el) => el.hasPossession === true)];
            if (currentEvents["main"]) {
              if (props.currentState.entities[0]) {
                currentEvents["main"]["entityId"] = entity ? entity.entityId : props.currentState.entities[0].entityId;
              }
            }
            break;
          case "notHasPossession":
            let notHasentity =
              props.currentState.entities[props.currentState.entities.findIndex((el) => el.hasPossession !== true)];
            if (currentEvents["main"]) {
              if (props.currentState.entities[0]) {
                currentEvents["main"]["entityId"] = notHasentity
                  ? notHasentity.entityId
                  : props.currentState.entities[0].entityId;
              }
            }
            break;
          case "hasArrow":
            let arrowEntity =
              props.currentState.entities[props.currentState.entities.findIndex((el) => el.hasArrow === true)];
            if (currentEvents["main"]) {
              if (props.currentState.entities[0]) {
                currentEvents["main"]["entityId"] = arrowEntity
                  ? arrowEntity.entityId
                  : props.currentState.entities[0].entityId;
              }
            }
            break;
          case "notHasArrow":
            let notHasArrowentity =
              props.currentState.entities[props.currentState.entities.findIndex((el) => el.hasArrow !== true)];
            if (currentEvents["main"]) {
              if (props.currentState.entities[0]) {
                currentEvents["main"]["entityId"] = notHasArrowentity
                  ? notHasArrowentity.entityId
                  : props.currentState.entities[0].entityId;
              }
            }
            break;
          default:
            break;
        }
        return true;
      });
    } else if (action.type === "entityId") {
      currentEvents["main"]["entityId"] =
        action.value.currentEntity === true
          ? currentEvents["main"].entityId
          : props.currentState.entities[
              props.currentState.entities.findIndex((el) => el.entityId !== currentEvents["main"].entityId)
            ].entityId;
      if (typeof action.value === "string") {
        currentEvents["main"]["entityId"] = action.value;
      }

      if (action.value.hasOwnProperty("hasPossession")) {
        currentEvents.main.entityId = props.currentState.entities.find(
          (entity) => entity.hasPossession === action.value.hasPossession,
        ).entityId;
      }
    } else if (action.type === "options") {
      const eventId = action.event ?? "main";
      let options = props.currentState.events[eventId].options ? props.currentState.events[eventId].options : {};
      action.value.map((option) => {
        options[option.option] = option.value;
        return true;
      });
      currentEvents[eventId]["options"] = options;
    } else if (action.type === "defaultLocations") {
      let team = props.currentState.entities.findIndex(
        (el) => el.entityId === props.currentState.events["main"].entityId,
      );
      currentEvents["main"]["x"] = props.workFlow.defaultLocations[team].x;
      currentEvents["main"]["y"] = props.workFlow.defaultLocations[team].y;
    } else {
      currentEvents["main"][action.type] = action.value;
    }
    // currentEvents["main"]["playId"] = props.currentState.playId;
    updateState("events", currentEvents);
  }

  function _clearData(action) {
    const clearEvents = props.currentState.events;
    const eventId = action.type ?? "main";
    action.value.forEach((variable) => {
      if (variable.option) {
        delete clearEvents[eventId].options[variable.option];
      } else {
        delete clearEvents[eventId][variable];
      }
    });
    updateState("events", clearEvents);
  }

  function _copyData(action) {
    let events = props.currentState.events;
    events["main"][action.value] = events[action.type][action.value];
    updateState("events", events);
  }

  function _copyEvent(action) {
    let currentEvents = props.currentState.events;
    if (action.type === "to") {
      let event = currentEvents["main"];
      currentEvents[action.value] = JSON.parse(JSON.stringify(event));
      updateState("events", currentEvents);
    } else {
      let currentTempEvent = JSON.parse(JSON.stringify(currentEvents[action.value]));
      currentEvents["main"] = currentTempEvent;
      updateState("events", currentEvents);
    }
  }

  function _copyFromPlay(action) {
    let currentEvents = props.currentState.events;
    let playId = currentEvents[action.type].playId;
    let copyEvents = props.eventStore.filter(
      (ev) => ev.playId === playId && action.value.eventTypes.includes(ev.eventType),
    );
    if (copyEvents) {
      copyEvents.forEach((ev) => {
        if (action.value.ignoreSubTypes && action.value.ignoreSubTypes.includes(ev.subType)) {
          // currentEvents[action.value.copyTo] = ev;
        } else {
          currentEvents[action.value.copyTo] = ev;
        }
      });
    }
    updateState("events", currentEvents);
  }

  function _startGame(action, props) {
    sendClockEvent("sport", "fixture", "start", props);
    sendClockEvent("sport", "period", "start", props);
  }

  function _clearEvent(action) {
    let currentEvents = props.currentState.events;
    if (currentEvents[action.value]) {
      delete currentEvents[action.value];
      updateState("events", currentEvents);
    }
  }

  function _deleteFromPlay(action) {
    let deleteEvent = props.eventStore.find(
      (ev) => ev.playId === props.currentState?.events[action.type]?.playId && ev.eventType === action.value,
    );

    if (deleteEvent) {
      deleteEvent.status = "deleted";
      deleteEvent.eventTime = getEventTimeForUpdatedDeletedEvents(deleteEvent);
      let action = {
        action: "sendEvent",
        event: deleteEvent,
        type: "custom",
      };
      sendEvent(action, props);
    }
  }

  function _setValue(action, value) {
    if (!props.currentState.insertMode || (props.currentState.insertMode && !props.currentState.insertMode.enabled)) {
      let tempEntities = JSON.parse(JSON.stringify(props.currentState.entities));
      let event =
        action.type !== "main" &&
        typeof props.currentState.temp !== "undefined" &&
        typeof props.currentState.temp[action.condition.event] !== "undefined"
          ? props.currentState.temp[action.condition.event]
          : props.currentState.events["main"];
      const currentEntity = event.entityId;

      // the app crashes if I try to start a new period in case of FH
      // and none of the teams has possession
      if (typeof currentEntity === "undefined") {
        return;
      }

      if (action.value.currentEntity === false) {
        tempEntities[tempEntities.findIndex((el) => el.entityId !== currentEntity)][value] = true;
        tempEntities[tempEntities.findIndex((el) => el.entityId === currentEntity)][value] = false;
        sendPossessionEvent(
          tempEntities[tempEntities.findIndex((el) => el.entityId !== currentEntity)]["entityId"],
          value,
          props,
          false,
          action,
        );
      } else {
        tempEntities[tempEntities.findIndex((el) => el.entityId === currentEntity)][value] = true;
        tempEntities[tempEntities.findIndex((el) => el.entityId !== currentEntity)][value] = false;
        sendPossessionEvent(
          tempEntities[tempEntities.findIndex((el) => el.entityId === currentEntity)]["entityId"],
          value,
          props,
          false,
          action,
        );
      }

      if (value === "hasPossession") _endPowerPlay();

      if (action.options && action.options.queued && action.options.queued === true) {
        let tempQueue = props.currentState.queue;
        let queuedActionEntities = [];
        tempEntities.map((entity) => {
          queuedActionEntities.push({
            entityId: entity.entityId,
            item: value,
            value: entity[value],
          });
          return true;
        });
        let queuedAction = {
          uid: uuidv1(),
          event: action.options.event,
          entities: queuedActionEntities,
        };
        tempQueue.push(queuedAction);
        updateState("queue", tempQueue);
      } else {
        updateState("entities", tempEntities);
      }
    }
  }

  function _softNewEvent() {
    let uuid = uuidv1();
    let currentEvents = props.currentState.events;
    currentEvents.main.eventId = uuid;
    let timestamp = new Date();
    currentEvents.main["timestamp"] = timestamp.toISOString();
    currentEvents.main["bench"] = false;
    currentEvents.main["coach"] = false;
    currentEvents.main["options"] = {};
  }

  function _newEvent(full = true) {
    let uuid = uuidv1();
    let currentEvents = props.currentState.events;
    currentEvents.main.eventId = uuid;
    if (full) {
      const timestamp = new Date().toISOString();
      const isImmutableDateTime = panel?.options?.immutableDateTime;
      currentEvents.main["eventTime"] = isImmutableDateTime ? timestamp : getEventTime();
      currentEvents.main["timestamp"] = timestamp;
      currentEvents.main["bench"] = false;
      currentEvents.main["coach"] = false;
      currentEvents.main["options"] = {};
      currentEvents.main["subType"] = undefined;
      currentEvents.main["eventType"] = undefined;
    }
    updateState("events", currentEvents);
  }

  function _newPlayId() {
    let uuid = uuidv1();
    let currentEvents = props.currentState.events;
    currentEvents.main.playId = uuid;
    updateState("events", currentEvents);
  }

  function _changeClock(value) {
    let csi = props.currentState.insertMode;
    if (!csi || (csi && !csi.enabled)) {
      let clock = props.currentState.clock;
      clock.clockRunning = value;
      clock.clockStatus = "auto";
      updateState("clock", clock);
    }
  }

  function _checkResets() {
    const lastPeriod = props.currentState.period.periodId - 1;
    let tempEntities = props.currentState.entities;
    //    Reset Fouls
    if (fixtureProfile.resetFoulsAfterPeriods.includes(lastPeriod)) {
      if (tempEntities[0]) {
        tempEntities[0].fouls = 0;
      }
      if (tempEntities[1]) {
        tempEntities[1].fouls = 0;
      }
      updateState("entities", tempEntities);
    }
    // Reset Timeouts
    if (fixtureProfile.resetTimeoutsAfterPeriods.includes(lastPeriod)) {
      if (tempEntities[0]) {
        tempEntities[0].timeouts = 0;
      }
      if (tempEntities[1]) {
        tempEntities[1].timeouts = 0;
      }
      updateState("entities", tempEntities);
    }
    // Switch Sides
    if (fixtureProfile.switchPlayingDirection.includes(lastPeriod)) {
      // let arrowEntity = tempEntities.findIndex((el) => el.hasArrow === true);
      // tempEntities[0].hasArrow = arrowEntity > 0 ? true : false;
      // tempEntities[1].hasArrow = arrowEntity < 1 ? true : false;
      updateState("entities", [tempEntities[1], tempEntities[0]]);
    }
  }

  function _adjustTeamValue(action) {
    if (!props.currentState.insertMode || (props.currentState.insertMode && !props.currentState.insertMode.enabled)) {
      let tempEntities = props.currentState.entities;
      let team =
        action.options.currentEntity === true
          ? tempEntities.findIndex((el) => el.entityId === props.currentState.events["main"].entityId)
          : tempEntities.findIndex((el) => el.entityId !== props.currentState.events["main"].entityId);

      if (isNaN(tempEntities[team][action.options.item])) {
        tempEntities[team][action.options.item] = 0;
      }
      tempEntities[team][action.options.item] =
        parseInt(tempEntities[team][action.options.item]) + parseInt(action.value);
      updateState("entities", tempEntities);
    }
  }

  function _switchValues(action) {
    let event = props.currentState.events[action.type];

    if (event) {
      event.status = "updated";
      event.eventTime = getEventTimeForUpdatedDeletedEvents(event);
      if (action.value.property === "options") {
        const matchingOptions = Object.keys(action.value.values[0]).every(
          (key) => Object.keys(event.options).includes(key) && event.options[key] === action.value.values[0][key],
        );
        event.options = matchingOptions ? action.value.values[1] : action.value.values[0];
      } else {
        event[action.value.property] =
          event[action.value.property] === action.value.values[0] ? action.value.values[1] : action.value.values[0];
      }
      let sendEv = {
        action: "sendEvent",
        event: event,
        type: "custom",
      };
      sendEvent(sendEv, props);
    }
  }

  function _switchValuesPlay(action) {
    let switchEvents = props.eventStore.filter(
      (ev) => ev.playId === props.currentState.events[action.type].playId && ev.eventType === action.value.eventType,
    );

    if (switchEvents) {
      switchEvents.forEach((ev) => {
        ev.status = "updated";
        ev.eventTime = getEventTimeForUpdatedDeletedEvents(ev);
        ev[action.value.property] =
          ev[action.value.property] === action.value.values[0] ? action.value.values[1] : action.value.values[0];
        let event = {
          action: "sendEvent",
          event: ev,
          type: "custom",
        };
        sendEvent(event, props);
      });
    }
  }

  function _calculateOnCourt() {
    let tempEntities = calculateOnCourt("all", props);
    updateState("entities", tempEntities);
  }

  function _formatClock(displayClock) {
    let clockParts = displayClock.split(":");
    return "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
  }

  function _modifyEvent(action) {
    const newCurrentEvents = { ...props.currentState.events };
    if (action.value === "swapEntities") {
      const currentEntityId = newCurrentEvents[action.type].entityId;
      const otherEntityId = props.currentState.entities.find((e) => e.entityId !== currentEntityId)?.entityId;
      newCurrentEvents[action.type].entityId = otherEntityId;
    }
    if (action.value === "assignPlayerWithRole::Goalie") {
      const currentEntityId = newCurrentEvents[action.type].entityId;
      const currentEntity = props.currentState.entities.find((ent) => ent.entityId === currentEntityId);
      const person = currentEntity?.persons.find((p) => p.position === "G" && p.active);
      if (person) {
        newCurrentEvents[action.type].personId = person.personId;
      }
    }

    if (action.value === "remapOptions") {
      const event = newCurrentEvents[action.type];
      if (event.options[action.fromField]) {
        let val = event.options[action.fromField];

        if (action.as === "String") {
          val = String(val);
        } else if (action.as === "Number") {
          val = parseInt(val, 10);
        }

        delete event.options[action.fromField];
        event.options[action.toField] = val;
      }
    }
    updateState("events", newCurrentEvents);
  }

  function _setPosTracking(value) {
    const { entities, events } = props.currentState;

    const { entityId } = events?.main ?? {};

    if (!entityId) {
      console.warn("Setting position tracking without active entity");
      return;
    }

    const currentEntity = entities.find((oneEntity) => oneEntity.entityId === entityId);

    if (!currentEntity) {
      console.warn("Invalid main event before setting position tracking");
      return;
    }

    if (value === "reset") {
      updateState("posTracking", null);
    } else {
      updateState("posTracking", { type: value, entity: currentEntity });
    }
  }

  function _endPowerPlay() {
    // no-op if PowerPlay is not enabled and active
    if (!isPowerPlayActive(props.currentState, fixtureProfile, props.eventStore)) return;

    const lastStartPPEvent = props.eventStore
      .filter((e) => e.eventType === "powerPlay" && e.subType === "start")
      .at(-1);

    const endPPEvent = {
      eventId: uuidv1(),
      playId: uuidv1(),
      eventType: "powerPlay",
      subType: "end",
      eventTime: getEventTime(),
      timestamp: new Date().toISOString(),
      clock: _formatClock(props.currentState.clock.displayTime),
      periodId: props.currentState.period.periodId,
      entityId: lastStartPPEvent.entityId,
    };
    sendEvent({ value: "new" }, props, endPPEvent);
  }

  function _handlePPEvent(action, props) {
    if (!isPowerPlayActive(props.currentState, fixtureProfile, props.eventStore)) {
      return;
    }

    const powerPlayEvents = ["assist", "turnover", "foul", "rebound", "block", "steal", "jumpBall"];
    const currentEvent = props.currentState.events[action.type];
    if (powerPlayEvents.includes(currentEvent.eventType)) {
      currentEvent.options = { ...currentEvent.options, powerPlay: true };
      updateState("events", props.currentState.events);
    }
  }

  return [actionsDone];
};
